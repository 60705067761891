<template>
  <AppMenuMobileSubmenu
    v-if="item.children.length"
    v-model:sub-menu-open="subMenuOpen"
    v-model:mobile-menu-open="mobileMenuOpen"
    :item="item"
    :class="{'translate-x-full': !subMenuOpen}"
  />
  <component
    :is="item.children.length ? 'button' : NuxtLink"
    :to="item.url"
    class="flex w-full items-center justify-between border-b border-gray-300 px-5 py-3 text-left font-semibold"
    @click="item.children ? subMenuOpen = true : mobileMenuOpen = false"
  >
    {{ item.label }}
    <UiIcon
      name="ChevronDown"
      class="-rotate-90 text-gray-400"
      size="small"
    />
  </component>
</template>

<script setup lang="ts">
import {NuxtLink} from '#components';
import {type MenuItem} from '~/graphql/graphql';

defineProps<{
  item: MenuItem
}>();

const subMenuOpen = ref(false);
const mobileMenuOpen = defineModel<boolean>('open');
</script>
