<template>
  <div class="sticky top-0 z-50 mb-10 border-b border-gray-300 bg-white">
    <div class="relative z-10 flex h-20 items-stretch">
      <div class="grid-gap mx-auto flex w-full max-w-screen-2xl items-stretch justify-between px-4 lg:px-10">
        <div class="flex items-center lg:hidden">
          <UiButton
            icon="Hamburger"
            type="secondary"
            icon-size="small"
            @click="mobileMenuOpen = !mobileMenuOpen"
          />
        </div>
        <NuxtLink
          class="shrink self-center max-lg:order-2"
          to="/"
        >
          <img
            class="max-lg:hidden"
            src="@/assets/images/logo.svg"
            alt="Uitvaart platform"
          >
          <img
            class="lg:hidden"
            src="@/assets/images/logo-mobile.svg"
            alt="Uitvaart platform"
          >
        </NuxtLink>

        <AppMenu />

        <AppMenuUser />
      </div>
    </div>
    <AppMenuMobile
      v-if="mobileMenuOpen"
      v-model:open="mobileMenuOpen"
    />
  </div>
</template>

<script lang="ts" setup>
const mobileMenuOpen = ref(false);
</script>
