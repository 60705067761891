<template>
  <div
    class="fixed top-0 z-10 mt-32 size-full max-h-[calc(100%_-_8rem)] overflow-y-auto bg-white pt-2 transition duration-500"
  >
    <button
      class="flex w-full items-center border-b border-gray-300 px-5 py-3 text-left text-gray"
      @click="subMenuOpen = false"
    >
      <UiIcon
        name="ArrowBack"
        size="small"
      />
    </button>
    <NuxtLink
      v-if="item.url"
      :to="item.url"
      class="flex w-full items-center justify-between border-b border-gray-300 px-5 py-3 text-left font-semibold"
      @click="mobileMenuOpen = false"
    >
      {{ item.label }}
      <UiIcon
        name="ChevronDown"
        class="-rotate-90 text-gray-400"
        size="small"
      />
    </NuxtLink>
    <div class="p-5">
      <component
        :is="child.url !== '#' ? NuxtLink : 'div'"
        v-for="child in item.children"
        :key="child"
        :to="child.url"
      >
        <div
          class="mb-3 font-semibold"
          :class="{'block text-sm font-medium text-primary': child.url !== '#'}"
        >
          {{ `${(child.label === "\{\{categories\}\}") ? 'Categorieën' : child.label}` }}
        </div>
        <div
          v-if="child.children.length"
          class="py-4.5 mb-4 flex columns-[10rem] flex-wrap"
        >
          <NuxtLink
            v-for="subChild in child.children"
            :key="subChild"
            :to="prefixedUrl(subChild.url)"
            class="mb-2 mr-2 inline-block truncate whitespace-nowrap rounded-full bg-secondary-300 p-2 py-1.5 text-xs font-medium uppercase text-secondary-800"
            @click="mobileMenuOpen = false"
          >
            {{ subChild.label }}
          </NuxtLink>
        </div>
        <div
          v-else-if="child.label === '{{categories}}'"
          class="columns-2 pb-6"
        >
          <NuxtLink
            v-for="category in categories"
            :key="category.id"
            :to="`/bedrijven/c/${category.slug}`"
            class="block truncate whitespace-nowrap py-1.5 text-sm font-medium text-primary"
            @click="mobileMenuOpen = false"
          >
            {{ category.name }}
          </NuxtLink>
        </div>
      </component>
      <UiAdvertisement
        position="menu"
        class="sm:max-w-xs"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import {NuxtLink} from '#components';
import {type MenuItem, useCategoriesQuery} from '~/graphql/graphql';

const subMenuOpen = defineModel<boolean>('subMenuOpen', {
  required: true,
});

const mobileMenuOpen = defineModel<boolean>('mobileMenuOpen');

const props = defineProps<{
  item: MenuItem
}>();

function prefixedUrl(url:string) {
  return prefix.value + url;
}

const prefix = computed(() => {
  switch (props.item?.label) {
    case 'Artikelen':
      return '/artikelen/c';
    case 'Bedrijven':
      return '/bedrijven/c';
    default:
      return '';
  }
});

const {result: categoriesQueryResult} = useCategoriesQuery();
const categories = computed(() => categoriesQueryResult.value?.categories ?? []);
</script>
