<template>
  <ul class="flex shrink-0 gap-6 max-lg:hidden">
    <li
      v-for="item in menu"
      :key="item.url"
      class="group flex items-center"
      :class="{'relative': !isMegaMenu(item)}"
    >
      <NuxtLink
        :to="item.url"
        class="flex h-full items-center text-sm font-medium"
        active-class="text-orange"
        @mouseenter="isMenuOpen = true"
      >
        {{ item.label }}
        <UiIcon
          v-if="item.children?.length"
          size="tiny"
          name="ChevronDown"
          class="ml-1 transition group-hover:rotate-180"
        />
      </NuxtLink>
      <AppMenuSubmenu
        v-if="item.children?.length"
        v-model:open="isMenuOpen"
        :item="item"
        :is-mega="isMegaMenu(item)"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import {type MenuItem} from '~/graphql/graphql';

const {menu} = useSofieMenu('MainMenu');
const isMenuOpen = ref(false);

function isMegaMenu(item: MenuItem) {
  return item.children?.[0]?.url === '#' || item.children?.[0]?.label === '{{ categories }}';
}
</script>
