<template>
  <div class="bg-secondary-700 py-14 text-white">
    <div class="container">
      <div class="grid-gap grid grid-cols-12">
        <div class="col-span-12 row-span-1 lg:col-span-8">
          <img
            src="@/assets/images/logo-white.svg"
            alt="Uitvaart Platform"
            class="mb-10 h-16"
          >
          <div class="grid-gap grid sm:grid-cols-8">
            <div class="col-span-3">
              <div class="footer-col-title">
                Adres & Socials
              </div>
              <ul class="space-y-2">
                <li class="flex items-center gap-3">
                  <UiIcon
                    name="Marker"
                    size="small"
                  />
                  <div class="text-sm">
                    {{ settings.address }}, {{ settings.city }}
                  </div>
                </li>
                <li class="flex items-center gap-3">
                  <UiIcon
                    name="Phone"
                    size="small"
                  />
                  <NuxtLink
                    :to="`tel:${settings.phone}`"
                    class="text-sm underline"
                  >
                    {{ settings.phone }}
                  </NuxtLink>
                </li>
                <li class="flex items-center gap-3">
                  <UiIcon
                    name="Envelope"
                    size="small"
                  />
                  <NuxtLink
                    :to="`mailto:${settings.email}`"
                    class="text-sm underline"
                  >
                    {{ settings.email }}
                  </NuxtLink>
                </li>
              </ul>
              <div class="-ml-1 mt-6 flex gap-4">
                <NuxtLink
                  v-if="settings.facebook"
                  :to="settings.facebook"
                >
                  <UiIcon name="fa6-brands:facebook-f" />
                </NuxtLink>
                <NuxtLink
                  v-if="settings.twitter"
                  :to="settings.twitter"
                >
                  <UiIcon name="fa6-brands:x-twitter" />
                </NuxtLink>
                <NuxtLink
                  v-if="settings.instagram"
                  :to="settings.instagram"
                >
                  <UiIcon name="fa6-brands:instagram" />
                </NuxtLink>
                <NuxtLink
                  v-if="settings.youtube"
                  :to="settings.youtube"
                >
                  <UiIcon name="fa6-brands:youtube" />
                </NuxtLink>
                <NuxtLink
                  v-if="settings.linkedin"
                  :to="settings.linkedin"
                >
                  <UiIcon name="fa6-brands:linkedin-in" />
                </NuxtLink>
                <NuxtLink
                  v-if="settings.tiktok"
                  :to="settings.tiktok"
                >
                  <UiIcon name="fa6-brands:tiktok" />
                </NuxtLink>
              </div>
            </div>
            <div class="col-span-3">
              <div class="footer-col-title">
                Populaire categorieën
              </div>
              <div class="flex flex-wrap gap-3">
                <UiTag
                  v-for="category in popularCategories"
                  :key="category.id"
                  :label="category.name"
                  :to="'/bedrijven/c/' + category.slug"
                  class="text-black"
                />
              </div>
            </div>
            <div class="col-span-2">
              <div class="footer-col-title">
                Navigeer
              </div>
              <ul>
                <li
                  v-for="(item, index) in menu"
                  :key="index"
                >
                  <NuxtLink
                    :to="item.url"
                    class="text-sm"
                  >
                    {{ item.label }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <UiCta
            :to="{name: 'account'}"
            type="secondary"
          >
            <UiCategoryLabel
              class="text-xs"
              label="Bedrijven"
              icon-flipped
            />
            <h2 class="mb-1">
              Jouw organisatie hier tonen?
            </h2>
            <p class="mb-0 text-base">
              Meld je aan om jouw diensten aan te bieden.
            </p>
          </UiCta>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {usePopularCategoriesQuery} from '~/graphql/graphql';

const {settings} = useSofieSettings();
const {menu} = useSofieMenu('FooterMenu');

const {result} = usePopularCategoriesQuery();
const popularCategories = computed(() => result.value?.popularCategories ?? []);
</script>

<style scoped>
.footer-col-title {
  @apply mb-4 text-base font-semibold;
}
</style>
