<template>
  <div class="order-3 flex items-center gap-4 max-lg:hidden">
    <NuxtLink :to="{name: 'favorieten'}">
      <UiIcon
        name="Heart"
        size="small"
        class="text-primary"
      />
    </NuxtLink>
    <AppMenuSocial class="hidden xl:flex" />
    <UiButton
      v-if="!user"
      label="Inloggen"
      :to="{name: 'auth-login'}"
    />
    <UiButton
      v-else
      type="secondary"
      label="Uitloggen"
      @click="signOut"
    />
    <UiButton
      v-if="user"
      class="hidden lg:flex"
      icon="User"
      type="secondary"
      :to="{name: 'account'}"
    />
  </div>
  <div class="order-3 flex items-center lg:hidden">
    <UiButton
      icon="User"
      type="secondary"
      :to="{name: 'account'}"
    />
  </div>
</template>

<script lang="ts" setup>
const user = useSofieUserWhenAuthenticated();

const {signOut} = useAuth();
</script>
