<template>
  <div>
    <div
      v-show="open"
      v-if="isMega"
      :class="menuVariants.big"
    >
      <div class="container flex w-full overflow-y-auto">
        <div class="flex w-full divide-x divide-gray-300">
          <div
            v-for="child in item.children"
            :key="child.label"
            :class="{'grow': child.label === categoriesPlaceholder}"
            class="flex flex-col px-6 pb-14 pt-6"
          >
            <div
              class="mb-6 shrink-0 overflow-clip truncate whitespace-nowrap text-lg font-semibold text-gray"
            >
              {{ child.label === categoriesPlaceholder ? 'Categorieën' : child.label }}
            </div>

            <div
              v-if="child.children.length"
              class="pb-6"
            >
              <div
                v-for="childItem in child.children"
                :key="childItem.url"
                class="mb-3.5 last:mb-0"
              >
                <NuxtLink
                  :to="prefixedUrl(childItem.url)"
                  class="inline-block overflow-clip truncate whitespace-nowrap rounded-full bg-secondary-300 p-2 text-xs font-medium uppercase text-secondary-800"
                  active-class="font-bold"
                  @click="open = false"
                >
                  {{ childItem.label }}
                </NuxtLink>
              </div>
            </div>
            <div
              v-else-if="child.label === categoriesPlaceholder"
              class="columns-[10rem] pb-6"
            >
              <div
                v-for="category in categories"
                :key="category.id"
                class="mb-3.5 last:mb-0"
              >
                <NuxtLink
                  :to="`/bedrijven/c/${category.slug}`"
                  class="block overflow-clip truncate whitespace-nowrap text-sm font-medium text-primary-500 underline"
                  active-class="font-bold"
                  @click="open = false"
                >
                  {{ category.name }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <div class="!border-l-0 pb-14 pt-20">
          <UiAdvertisement
            position="menu_large"
            class="sm:max-w-xs"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      v-show="open"
      :class="menuVariants.small"
      class="flex gap-x-6"
    >
      <div class="flex flex-col space-y-4">
        <NuxtLink
          v-for="childItem in item.children"
          :key="childItem.url"
          :to="childItem.url"
          class="block overflow-clip truncate whitespace-nowrap text-sm font-medium text-primary-500 underline"
          active-class="font-bold"
          @click="open = false"
        >
          {{ childItem.label }}
        </NuxtLink>
      </div>
      <UiAdvertisement
        position="menu"
        class="min-w-xs w-56"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {type MenuItem, useCategoriesQuery} from '~/graphql/graphql';

const props = defineProps<{
  item: MenuItem
  isMega: boolean
}>();

const categoriesPlaceholder = '{{categories}}';

function prefixedUrl(url:string) {
  return prefix.value + url;
}

const prefix = computed(() => {
  switch (props.item?.label) {
    case 'Artikelen':
      return '/artikelen/c';
    case 'Bedrijven':
      return '/bedrijven/c';
    default:
      return '';
  }
});

const menuVariants = {
  big: 'pointer-events-none absolute left-0 top-full flex max-h-[calc(100vh_-_200px)] w-screen -translate-y-4 rounded-b-3xl bg-white opacity-0 shadow-lg transition group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100',
  small: 'pointer-events-none absolute -left-6 top-full p-6 flex max-h-[calc(100vh_-_200px)] w-fit -translate-y-4 rounded-b-3xl bg-white opacity-0 shadow-lg transition group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100',
};

const {result: categoriesQueryResult} = useCategoriesQuery();
const categories = computed(() => categoriesQueryResult.value?.categories ?? []);

const open = defineModel<boolean>('open');
</script>
