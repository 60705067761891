<template>
  <div
    class="fixed top-0 flex h-screen w-full flex-col justify-between bg-white pt-32"
  >
    <div>
      <template
        v-for="menuItem in menu"
        :key="menuItem"
      >
        <AppMenuMobileMenuItem
          v-model:open="open"
          :item="menuItem"
        />
      </template>
    </div>
    <div class="grid-gap my-12 flex justify-center">
      <UiButton
        :to="{name: 'favorieten'}"
        label="Favorieten"
        @click="open = false"
      />
      <UiButton
        v-if="!user"
        styling="secondary"
        label="Inloggen"
        :to="{name: 'auth-login'}"
      />
      <UiButton
        v-else
        styling="secondary"
        label="Uitloggen"
        @click="signOut"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const {menu} = useSofieMenu('MainMenu');

const open = defineModel<boolean>('open');

const user = useSofieUserWhenAuthenticated();

const {signOut} = useAuth();
</script>
